// eslint-disable-next-line no-shadow
export enum TranslationLabel {
    ErrorHttpErrorTitle = 'ERROR_MESSAGE.HTTP_ERROR_TITLE',
    ErrorHttpErrorMessage = 'ERROR_MESSAGE.HTTP_ERROR_MESSAGE',
    ErrorMessageRequiredField = 'ERROR_MESSAGE.REQUIRED_FIELD',
    ErrorMessageNumbersOnlyField = 'ERROR_MESSAGE.NUMBERS_ONLY',
    ErrorFillAllRequiredFields = 'ERROR_MESSAGE.FILL_ALL_REQUIRED_FIELDS',
    ErrorInvalidUsernameOrPassword = 'ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD',
    ErrorInvalidTextFormat = 'ERROR_MESSAGE.INVALID_TEXT_FORMAT',
    ErrorTextPageDoesNotExist = 'ERROR_MESSAGE.TEXT_PAGE_DOES_NOT_EXIST',
    ErrorWordNotFound = 'ERROR_MESSAGE.WORD_NOT_FOUND',
    ErrorWordIsTooShort = 'ERROR_MESSAGE.WORD_IS_TOO_SHORT',
    ErrorUploadError = 'ERROR_MESSAGE.UPLOAD_ERROR',
    ErrorMultipleUpload = 'ERROR_MESSAGE.MULTIPLE_UPLOAD',
    ErrorIncorrectFileType = 'ERROR_MESSAGE.INCORRECT_FILE_TYPE',
    ErrorSearchTermLength = 'ERROR_MESSAGE.SEARCH_TERM_LENGTH',
    ErrorSearchNoResultsFound = 'ERROR_MESSAGE.NO_RESULTS',

    CommonConfirmationTitle = 'COMMON.CONFIRMATION_TITLE',
    CommonBoxDeleteButton = 'COMMON.DELETE_BUTTON',
    CommonBoxUnpublishButton = 'COMMON.UNPUBLISH_BUTTON',
    CommonBoxCancelButton = 'COMMON.CANCEL_BUTTON',
    CommonBoxSaveButton = 'COMMON.SAVE_BUTTON',
    CommonBoxHistoryButton = 'COMMON.HISTORY_BUTTON',
    CommonSearchButton = 'COMMON.SEARCH_BUTTON',
    CommonNoFoundResults = 'COMMON.NO_FOUND_RESULTS',
    CommonBackLabel = 'COMMON.BACK_LABEL',
    CommonAllFilterLabel = 'COMMON.ALL_FILTER_LABEL',
    CommonDownloadInProgress = 'COMMON.DOWNLOAD_IN_PROGRESS',
    CommonDownloadCompleted = 'COMMON.DOWNLOAD_COMPLETED',

    SharedActionButtonsEditButton = 'SHARED.ACTION_BUTTONS.EDIT_BUTTON',
    SharedActionButtonsCancelPublishButton = 'SHARED.ACTION_BUTTONS.CANCEL_PUBLISH_BUTTON',
    SharedActionButtonsPublishButton = 'SHARED.ACTION_BUTTONS.PUBLISH_BUTTON',
    SharedActionButtonsDeleteButton = 'SHARED.ACTION_BUTTONS.DELETE_BUTTON',
    SharedActionButtonsRemovePublication = 'SHARED.ACTION_BUTTONS.REMOVE_PUBLICATION',
    SharedActionButtonsDeleteConfirmation = 'SHARED.ACTION_BUTTONS.DELETE_CONFIRMATION',
    SharedTextEditorBulgarianButton = 'SHARED.TEXT_EDITOR.BULGARIAN_BUTTON',
    SharedTextEditorChurchSlavonic = 'SHARED.TEXT_EDITOR.CHURCH_SLAVONIC_BUTTON',
    SharedLastEditedLastEdit = 'SHARED.LAST_EDITED.LAST_EDIT',

    AppMainFooter = 'APP.MAIN.FOOTER',
    AppMainProjectInfo = 'APP.MAIN.PROJECT_INFO',
    AppMenuHome = 'APP.MENU.HOME',
    AppMenuLogin = 'APP.MENU.LOGIN',
    AppMenuTextCorpus = 'APP.MENU.TEXT_CORPUS',
    AppMenuContents = 'APP.MENU.CONTENTS',
    AppMenuSearch = 'APP.MENU.SEARCH',
    AppMenuHistoricalDict = 'APP.MENU.HISTORICAL_DICTIONARY',
    AppMenuWordForms = 'APP.MENU.WORD_FORMS',
    AppMenuEuthymiusDict = 'APP.MENU.SAINT_EUTHYMIUS_DICTIONARY',
    AppMenuCompositeDict = 'APP.MENU.COMPOSITE_DICTIONARY',
    AppMenuMedievalDict = 'APP.MENU.MEDIEVAL_DICTIONARY',
    AppMenuNewText = 'APP.MENU.NEW_TEXT',
    AppMenuNewWord = 'APP.MENU.NEW_WORD',
    AppMenuOtherResources = 'APP.MENU.OTHER_RESOURCES',
    AppMenuAbout = 'APP.MENU.ABOUT',
    AppMenuLogout = 'APP.MENU.LOGOUT',
    AppLoginTitle = 'APP.LOGIN.TITLE',
    AppLoginUsernamePlaceholder = 'APP.LOGIN.USERNAME_PLACEHOLDER',
    AppLoginPasswordPlaceholder = 'APP.LOGIN.PASSWORD_PLACEHOLDER',
    AppLoginButtonLabel = 'APP.LOGIN.LOGIN_BUTTON_LABEL',
    AppLoginWrongCredentialsMsg = 'APP.LOGIN.LOGIN_WRONG_CREDENTIALS',
    AppAboutPageBreadcrumb = 'APP.ABOUT_PAGE.BREADCRUMB',
    AppAboutPageTitle = 'APP.ABOUT_PAGE.TITLE',
    AppAboutPageFinanceParagraph = 'APP.ABOUT_PAGE.FINANCE_PARAGRAPH',
    AppAboutPageProjectObjective = 'APP.ABOUT_PAGE.PROJECT_OBJECTIVE',
    AppAboutPageObjectiveParagraph1 = 'APP.ABOUT_PAGE.OBJECTIVE_PARAGRAPH_1',
    AppAboutPageObjectiveParagraph2 = 'APP.ABOUT_PAGE.OBJECTIVE_PARAGRAPH_2',
    AppAboutPageProject1Title = 'APP.ABOUT_PAGE.PROJECT_1_TITLE',
    AppAboutPageProject1Subtitle = 'APP.ABOUT_PAGE.PROJECT_1_SUBTITLE',
    AppAboutPageProject2Title = 'APP.ABOUT_PAGE.PROJECT_2_TITLE',
    AppAboutPageProject2Subtitle = 'APP.ABOUT_PAGE.PROJECT_2_SUBTITLE',
    AppAboutPageProject3Title = 'APP.ABOUT_PAGE.PROJECT_3_TITLE',
    AppAboutPageProject3Subtitle = 'APP.ABOUT_PAGE.PROJECT_3_SUBTITLE',
    AppAboutPageProject4Title = 'APP.ABOUT_PAGE.PROJECT_4_TITLE',
    AppAboutPageProject4Subtitle = 'APP.ABOUT_PAGE.PROJECT_4_SUBTITLE',
    AppAboutPageProject5Title = 'APP.ABOUT_PAGE.PROJECT_5_TITLE',
    AppAboutPageProject5Subtitle = 'APP.ABOUT_PAGE.PROJECT_5_SUBTITLE',
    AppOtherResourcesLink1Paragraph1 = 'APP.OTHER_RESOURCES.LINK_1_PARAGRAPH_1',
    AppOtherResourcesLink1Paragraph2 = 'APP.OTHER_RESOURCES.LINK_1_PARAGRAPH_2',
    AppOtherResourcesLink2Paragraph1 = 'APP.OTHER_RESOURCES.LINK_2_PARAGRAPH_1',
    AppOtherResourcesLink2Paragraph2 = 'APP.OTHER_RESOURCES.LINK_2_PARAGRAPH_2',
    AppOtherResourcesLink3Paragraph1 = 'APP.OTHER_RESOURCES.LINK_3_PARAGRAPH_1',

    CorpusTextCorpusAddText = 'CORPUS.TEXT_CORPUS.ADD_TEXT',
    CorpusTextCorpusEditor = 'CORPUS.TEXT_CORPUS.EDITOR',
    CorpusTextCorpusFileUpload = 'CORPUS.TEXT_CORPUS.FILE_UPLOAD',
    CorpusVariantReadingLabel = 'CORPUS.VARIANT_READING',
    CorpusNoteLabel = 'CORPUS.NOTE',
    CorpusTitleLabel = 'CORPUS.TITLE_LABEL',
    CorpusLatinTitleLabel = 'CORPUS.LATIN_TITLE_LABEL',
    CorpusChuTitleLabel = 'CORPUS.CHU_TITLE_LABEL',
    CorpusGenreLabel = 'CORPUS.GENRE_LABEL',
    CorpusAuthorLabel = 'CORPUS.AUTHOR_LABEL',
    CorpusTranslationLabel = 'CORPUS.TRANSLATION_LABEL',
    CorpusManuscriptDateLabel = 'CORPUS.MANUSCRIPT_DATE_LABEL',
    CorpusTranslationDateLabel = 'CORPUS.TRANSLATION_DATE_LABEL',
    CorpusTranscriptDateLabel = 'CORPUS.TRANSCRIPT_DATE_LABEL',
    CorpusSpellingLabel = 'CORPUS.SPELLING_LABEL',
    CorpusManuscriptNameLabel = 'CORPUS.MANUSCRIPT_NAME_LABEL',
    CorpusManuscriptStorageLabel = 'CORPUS.MANUSCRIPT_STORAGE_LABEL',
    CorpusManuscriptSignatureLabel = 'CORPUS.MANUSCRIPT_SIGNATURE_LABEL',
    CorpusEditionLabel = 'CORPUS.EDITION',
    CorpusPagesLabel = 'CORPUS.PAGES_LABEL',
    CorpusVersionLabel = 'CORPUS.VERSION_LABEL',
    CorpusStatusLabel = 'CORPUS.STATUS_LABEL',
    CorpusStatusPublished = 'CORPUS.STATUS_PUBLISHED',
    CorpusStatusUnpublished = 'CORPUS.STATUS_UNPUBLISHED',
    CorpusDocIdLabel = 'CORPUS.DOC_ID_LABEL',
    CorpusNormalizationLabel = 'CORPUS.NORMALIZATION_LABEL',
    CorpusGlobalNoteLabel = 'CORPUS.GLOBAL_NOTE_LABEL',
    CorpusTextFormVersion = 'CORPUS.TEXT_FORM.VERSION',
    CorpusTextFormTitleCreate = 'CORPUS.TEXT_FORM.TITLE_CREATE',
    CorpusTextFormTitleEdit = 'CORPUS.TEXT_FORM.TITLE_EDIT',
    CorpusTextFormInfoSubtext1 = 'CORPUS.TEXT_FORM.INFO_SUBTEXT_1',
    CorpusTextFormInfoSubtext2 = 'CORPUS.TEXT_FORM.INFO_SUBTEXT_2',
    CorpusTextFormTitleLabel = 'CORPUS.TEXT_FORM.TITLE_LABEL',
    CorpusTextFormTitleLanguageLabel = 'CORPUS.TEXT_FORM.TITLE_LANGUAGE_LABEL',
    CorpusTextFormAltTitleLabel = 'CORPUS.TEXT_FORM.ALT_TITLE_LABEL',
    CorpusTextFormAltTitleLanguageLabel = 'CORPUS.TEXT_FORM.ALT_TITLE_LANGUAGE_LABEL',
    CorpusTextFormTranslationLabel = 'CORPUS.TEXT_FORM.TRANSLATION_LABEL',
    CorpusTextFormEditionLabel = 'CORPUS.TEXT_FORM.EDITION_LABEL',
    CorpusTextFormNormalizedTextLabel = 'CORPUS.TEXT_FORM.NORMALIZED_TEXT_LABEL',
    CorpusTextFormChooseLanguage = 'CORPUS.TEXT_FORM.CHOOSE_LANGUAGE',
    CorpusTextFormChooseGenre = 'CORPUS.TEXT_FORM.CHOOSE_GENRE',
    CorpusTextFormChooseSpelling = 'CORPUS.TEXT_FORM.CHOOSE_SPELLING',
    CorpusTextFormTranscriptions = 'CORPUS.TEXT_FORM.TRANSCRIPTIONS',
    CorpusTextFormTranscriptionsDesc = 'CORPUS.TEXT_FORM.TRANSCRIPTIONS_DESC',
    CorpusTextFormTranscriptionsAbbr = 'CORPUS.TEXT_FORM.TRANSCRIPTIONS_ABBR',
    CorpusTextFormTranscriptionsTitle = 'CORPUS.TEXT_FORM.TRANSCRIPTIONS_TITLE',
    CorpusTextFormTranscriptionsAddRowButton = 'CORPUS.TEXT_FORM.TRANSCRIPTIONS_ADD_ROW_BUTTON',
    CorpusTextFormTranscriptionsAddButton = 'CORPUS.TEXT_FORM.TRANSCRIPTIONS_ADD_BUTTON',
    CorpusTextViewOpenText = 'CORPUS.TEXT_VIEW.OPEN_TEXT',
    CorpusTextViewContentLabel = 'CORPUS.TEXT_VIEW.CONTENT_LABEL',
    CorpusTextViewRefreshContentLabel = 'CORPUS.TEXT_VIEW.CONTENT_REFRESH_LABEL',
    CorpusTextViewNoContentLabel = 'CORPUS.TEXT_VIEW.NO_CONTENT_LABEL',
    CorpusTextViewExportButton = 'CORPUS.TEXT_VIEW.EXPORT_BUTTON',
    CorpusTextViewLineNumbering = 'CORPUS.TEXT_VIEW.LINE_NUMBERING',
    CorpusTextViewNoLineNumbering = 'CORPUS.TEXT_VIEW.NO_LINE_NUMBERING',
    CorpusTextViewFiveLineNumbering = 'CORPUS.TEXT_VIEW.FIVE_LINE_NUMBERING',
    CorpusTextViewEveryLineNumbering = 'CORPUS.TEXT_VIEW.EVERY_LINE_NUMBERING',
    CorpusTextViewReadButton = 'CORPUS.TEXT_VIEW.READ_BUTTON',
    CorpusTextViewAnnotateButton = 'CORPUS.TEXT_VIEW.ANNOTATE_BUTTON',
    CorpusTextViewPageNameInput = 'CORPUS.TEXT_VIEW.PAGE_NAME_INPUT',
    CorpusTextViewMorphologicalAnalysisButton = 'CORPUS.TEXT_VIEW.VIEW_MORPH_ANALYSIS_BUTTON',
    CorpusTextViewAddAnnotation = 'CORPUS.TEXT_VIEW.ADD_ANNOTATION',
    CorpusTextViewEditAnnotation = 'CORPUS.TEXT_VIEW.EDIT_ANNOTATION',
    CorpusTextViewOriginalText = 'CORPUS.TEXT_VIEW.ORIGINAL_TEXT',
    CorpusTextViewMorphologicalAnalysis = 'CORPUS.TEXT_VIEW.MORPH_ANALYSIS',
    CorpusTextViewRunAnalysis = 'CORPUS.TEXT_VIEW.RUN_ANALYSIS',
    CorpusTextViewRunAIAnalysis = 'CORPUS.TEXT_VIEW.RUN_AI_ANALYSIS',
    CorpusTextViewDownloadReport = 'CORPUS.TEXT_VIEW.DOWNLOAD_REPORT',
    CorpusPageActionsEditPage = 'CORPUS.PAGE_ACTIONS.EDIT_PAGE',
    CorpusPageActionsDeletePage = 'CORPUS.PAGE_ACTIONS.DELETE_PAGE',
    CorpusPageActionsAddPage = 'CORPUS.PAGE_ACTIONS.ADD_PAGE',
    CorpusPageEditPageName = 'CORPUS.PAGE_EDITOR.PAGE_NAME',
    CorpusPageEditPageNum = 'CORPUS.PAGE_EDITOR.PAGENUM',
    CorpusPageEditColumn = 'CORPUS.PAGE_EDITOR.COLUMN',
    CorpusPageEditPageType = 'CORPUS.PAGE_EDITOR.PAGE_TYPE',
    CorpusPageEditChoosePageType = 'CORPUS.PAGE_EDITOR.CHOOSE_PAGE_TYPE',
    CorpusPageEditContents = 'CORPUS.PAGE_EDITOR.CONTENTS',
    CorpusTextViewDeleteConfirmationText = 'CORPUS.TEXT_VIEW.DELETE_CONFIRMATION_TEXT',
    CorpusAnnotationFormAnnotationTypeLabel = 'CORPUS.ANNOTATION_FORM.ANNOTATION_TYPE_LABEL',
    CorpusAnnotationFormRendLabel = 'CORPUS.ANNOTATION_FORM.REND_LABEL',
    CorpusAnnotationFormRendHelpTooltip = 'CORPUS.ANNOTATION_FORM.REND_HELP',
    CorpusAnnotationFormTextLabel = 'CORPUS.ANNOTATION_FORM.TEXT_LABEL',
    CorpusAnnotationFormChooseType = 'CORPUS.ANNOTATION_FORM.CHOOSE_TYPE',
    CorpusAnnotationFormSelectedWords = 'CORPUS.ANNOTATION_FORM.SELECTED_WORDS',
    CorpusPageEditorVersion = 'CORPUS.PAGE_EDITOR.VERSION',
    CorpusAddExistingPageTitle = 'CORPUS.ADD_EXISTING_PAGE.TITLE',
    CorpusNewPageTitle = 'CORPUS.NEW_PAGE.TITLE',
    CorpusFileImportDragFile = 'CORPUS.FILE_IMPORT.DRAG_FILE',
    CorpusFileImportOr = 'CORPUS.FILE_IMPORT.OR',
    CorpusFileImportChooseButton = 'CORPUS.FILE_IMPORT.CHOOSE_BUTTON',
    CorpusFileImportAllowedFiles = 'CORPUS.FILE_IMPORT.ALLOWED_FILES',
    CorpusFileImportSuccessfulUpload = 'CORPUS.FILE_IMPORT.SUCCESSFUL_UPLOAD',
    CorpusFileImportDeleteFile = 'CORPUS.FILE_IMPORT.DELETE_FILE',
    CorpusFileImportViewButton = 'CORPUS.FILE_IMPORT.VIEW_BUTTON',
    CorpusFileImportUploadButton = 'CORPUS.FILE_IMPORT.UPLOAD_BUTTON',
    CorpusFileImportUploadFile = 'CORPUS.FILE_IMPORT.UPLOAD_FILE',

    HistoryTitle = 'HISTORY.TITLE',
    HistoryRestoreMessage = 'HISTORY.RESTORE_MESSAGE',
    HistoryListVersionHeader = 'HISTORY.HISTORY_LIST.VERSION_HEADER',
    HistoryListCreateDateHeader = 'HISTORY.HISTORY_LIST.CREATE_DATE_HEADER',
    HistoryListLastChangeHeader = 'HISTORY.HISTORY_LIST.LAST_CHANGE_HEADER',
    HistoryListChangedByHeader = 'HISTORY.HISTORY_LIST.CHANGED_BY_HEADER',
    HistoryListActionsHeader = 'HISTORY.HISTORY_LIST.ACTIONS_HEADER',
    HistoryListCurrentVersion = 'HISTORY.HISTORY_LIST.CURRENT_VERSION',
    HistoryListPreviewButton = 'HISTORY.HISTORY_LIST.PREVIEW_BUTTON',
    HistoryListRestore = 'HISTORY.HISTORY_LIST.RESTORE',
    HistoryListConfirmationHeader = 'HISTORY.HISTORY_LIST.CONFIRMATION_HEADER',
    HistoryListCancelButton = 'HISTORY.HISTORY_LIST.CANCEL_BUTTON',
    HistoryOlderVersionBoxVersion = 'HISTORY.OLDER_VERSION_BOX.VERSION',
    HistoryOlderVersionBoxMessage = 'HISTORY.OLDER_VERSION_BOX.MESSAGE',
    HistoryOlderVersionBoxNewVerLink = 'HISTORY.OLDER_VERSION_BOX.NEW_VER_LINK',
    HistoryOlderVersionBoxAllVersions = 'HISTORY.OLDER_VERSION_BOX.ALL_VERSIONS',

    DictionaryWordDemoHideParadigms = 'DICTIONARY.WORD_DEMO.HIDE_PARADIGMS',
    DictionaryWordDemoShowParadigms = 'DICTIONARY.WORD_DEMO.SHOW_PARADIGMS',
    DictionaryWordStatusPublished = 'DICTIONARY.WORD.STATUS_PUBLISHED',
    DictionaryWordStatusUnpublished = 'DICTIONARY.WORD.STATUS_UNPUBLISHED',
    DictionarySourcesAbbreviations = 'DICTIONARY.SOURCES.ABBREVIATIONS',
    DictionarySourcesOldBulgarianMonuments = 'DICTIONARY.SOURCES.OLD_BULGARIAN_MONUMENTS',
    DictionarySourcesAbbreviation = 'DICTIONARY.SOURCES.ABBREVIATION',
    DictionarySourcesSearchPlaceholder = 'DICTIONARY.SOURCES.SEARCH_PLACEHOLDER',
    DictionarySourcesFullName = 'DICTIONARY.SOURCES.FULL_NAME',
    DictionaryWordSearchTitle = 'DICTIONARY.WORD_SEARCH.TITLE',
    DictionaryWordSearchHistDictInfo = 'DICTIONARY.WORD_SEARCH.DICT_INFO',
    DictionaryWordSearchSearchPlaceholder = 'DICTIONARY.WORD_SEARCH.SEARCH_PLACEHOLDER',
    DictionaryWordSearchChooseALetter = 'DICTIONARY.WORD_SEARCH.CHOOSE_A_LETTER',
    DictionaryFormsTitle = 'DICTIONARY.FORMS.TITLE',
    DictionaryFormsSearchPlaceholder = 'DICTIONARY.FORMS.SEARCH_PLACEHOLDER',
    DictionaryFormsShowEverything = 'DICTIONARY.FORMS.SHOW_EVERYTHING',
    DictionaryFormsFoundWordForms = 'DICTIONARY.FORMS.FOUND_WORD_FORMS',
    DictionaryFormsFor = 'DICTIONARY.FORMS.FOR',
    DictionaryFormsSeconds = 'DICTIONARY.FORMS.SECONDS',
    DictionaryFormsAllWordForms = 'DICTIONARY.FORMS.ALL_WORD_FORMS',
    DictionaryFormsArticle = 'DICTIONARY.FORMS.ARTICLE',
    DictionaryFormsTitleWord = 'DICTIONARY.FORMS.TITLE_WORD',
    DictionaryFormsWordForm = 'DICTIONARY.FORMS.WORD_FORM',
    DictionaryFormsConjugation = 'DICTIONARY.FORMS.Conjugation',
    DictionaryFormsTag = 'DICTIONARY.FORMS.TAG',
    DictionaryFormsWordForms = 'DICTIONARY.FORMS.WORD_FORMS',
    DictionaryEditTitle = 'DICTIONARY.EDIT_TITLE',
    DictionaryEditInfoText = 'DICTIONARY.EDIT_INFO_TEXT',

    SearchTitle = 'SEARCH.TITLE',
    SearchSearchPlaceholder = 'SEARCH.SEARCH_PLACEHOLDER',
    SearchChooseSource = 'SEARCH.CHOOSE_SOURCE',
    SearchChooseDictionary = 'SEARCH.CHOOSE_DICTIONARY',
    SearchFoundDictionaryArticles = 'SEARCH.FOUND_DICTIONARY_ARTICLES',
    SearchFor = 'SEARCH.FOR',
    Search = 'SEARCH',
    SearchSeconds = 'SEARCH.SECONDS',
    SearchDocumentHeading = 'SEARCH.DOCUMENT_HEADING',
    SearchTitleHeading = 'SEARCH.TITLE_HEADING',
    SearchContextHeading = 'SEARCH.CONTEXT_HEADING',
    SearchRankHeading = 'SEARCH.RANK_HEADING',
    SearchArticleHeading = 'SEARCH.ARTICLE_HEADING',
    SearchTitleWordHeading = 'SEARCH.TITLE_WORD_HEADING',
    SearchTextCorpusSource = 'SEARCH.TEXT_CORPUS_SOURCE',
    SearchHistDictSource = 'SEARCH.HIST_DICT_SOURCE',

    PageNotFoundTitle = 'PAGE.NOT_FOUND_TITLE',
    PageNotFoundDetail = 'PAGE.NOT_FOUND_DETAIL',
}
