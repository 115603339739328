export class Dictionary {

    constructor(dict: any) {
        this.id = dict.id;
        this.abbr = dict.abbr;
        this.full_name = dict.full_name;
        this.description = dict.description;
        this.annotation = dict.annotation;
        this.full_name_en = dict.full_name_en;
        this.description_en = dict.description_en;
        this.annotation_en = dict.annotation_en;
    }

    id: string;
    abbr: string;
    full_name: string;
    description: string;
    annotation: string;
    full_name_en?: string;
    description_en?: string;
    annotation_en?: string;

    getName(lang: string): string {
        if (lang === 'bg') {
            return this.full_name;
        } else return this.full_name_en;
    }
}
