<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h1>{{translationLabel.AppMenuOtherResources | translate}}</h1>

            <h2>Thesaurus Linguae Ioannis Damasceni et Aristotelis</h2>
            <p>(TLIDA: <a href="http://theol.uni-sofia.bg/caspbl/thesaurus-linguae-ioannis-damasceni-et-aristotelis/"
                          target="_blank">http://theol.uni-sofia.bg/caspbl/thesaurus-linguae-ioannis-damasceni-et-aristotelis/</a>)
                {{translationLabel.AppOtherResourcesLink3Paragraph1 | translate}}
            </p>

            <h2>Thesaurus Theologiae Maximi Confessoris</h2>
            <p [innerHTML]="translationLabel.AppOtherResourcesLink1Paragraph1 | translate"></p>
            <p [innerHTML]="translationLabel.AppOtherResourcesLink1Paragraph2 | translate"></p>

            <h2>The Thesaurus Linguae Maximi Confessoris</h2>
            <p [innerHTML]="translationLabel.AppOtherResourcesLink2Paragraph1 | translate"></p>
            <p [innerHTML]="translationLabel.AppOtherResourcesLink2Paragraph2 | translate"></p>
        </div>
    </div>
</div>
