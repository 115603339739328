const vk = require('./virtualkeyboard')
//ꙃѫѭѧѩꙙыꙋ́͗҃ѳѡѣ
//ꙗюертꙁуопшщ
//асдфгхїклж
//ъьцвбнмꙑѹ-

//ꙃѫѭѧѩꙙыꙋ́͗҃ѳѡѣ
//ꙑуешщксдꙁц]
//ьꙗаожгтнвмч"
//юїъѹфхпрлб

var load = function () {
        vk.VirtualKeyboard.addLayoutList(
            {
                code: 'EN-US'
                , name: 'United States-International'
                , normal: '`1234567890-=\\qwertyuiop[]asdfghjkl;\'zxcvbnm,./'
                , shift:{0:'~!@#$%^&*()_+|',24:'{}',35:':"',44:'<>?'}
                , alt:{1:'¡²³¤€¼½¾‘’¥×¬äåé®þüúíóö«»áßð',34:'ø¶´æ',39:'©',42:'ñµç',46:'¿'}
                , shift_alt:{1:'¹',4:'£',12:'÷¦',27:'§',35:'°¨',39:'¢'}
                , dk:{'^':'aâeêuûiîoôAÂEÊUÛIÎOÔ ^','\'':'cçaáeéuúiíyýoóCÇAÁEÉUÚIÍYÝOÓ \'','\"':'aäeëuüiïyÿoöAÄEËUÜIÏOÖ "','`':'aàeèuùiìoòAÀEÈUÙIÌOÒ `','~':'nñaãoõNÑAÃOÕ ~'}
            },
            {
                code: 'BG'
                , name: 'Bulgarian Phonetic'
                , normal: 'ч1234567890-=юявертъуиопшщасдфгхйкл;\'зьцжбнм,./'
                , shift: {1: '!@#$%^&*()_+', 35: ':"', 44: '<>?'}
                , alt: {0: '`', 4: '€‰', 8: '∞', 11: '÷≈\\ы', 16: 'ё®™', 22: '°', 24: '[]', 29: 'ø', 39: '©э', 42: '№µ≤≥'}
                , shift_alt: {0: '~', 4: '§', 12: '±|', 24: '{}'}
            },
            {
                code: 'CU',
                name: 'Ancient Bulgarian (Phonetic)',
                normal: 'ꙃѫѭѧѩꙙыꙋ́͗҃ѳѡѣꙗюертꙁуопшщасдфгхїклжъьцвбнмꙑѹ-',
                shift: {3: 'ꙹⷲ҃̀͑', 12: 'Ꙍ', 21: '', 35: '', 46: ''},
                alt: {0: 'ꙅⷾⷿⷽ', 6: 'ꙹⷹ̋҇ⷴ', 13: 'ⷺⷼⷻⷷⷬⷮⷥꙷⷪⷫⷲⷳⷶⷭⷣⷢⷯіⷦⷧⷱⷤꙸⷯⷰⷡⷠⷩⷨѿ'},
                shift_alt: {
                    2: 'єѤі',
                    8: '̏͠Ѻ',
                    14: 'і',
                    16: 'є',
                    22: '',
                    27: 'ѕЅ',
                    30: 'ꙅꙄ',
                    37: 'Ꙃ',
                    39: 'ⷳⷥ',
                    44: 'ⷹ͞'
                },
                caps: {3: 'ꙹⷲ҃', 21: '', 35: ''},
                shift_caps: {3: 'ѧѩꙙыꙋ', 21: '', 35: ''}
            },
            {
                code: 'CU-BDS',
                name: 'Ancient Bulgarian (БДС)',
                normal: 'ꙃѫѭѧѩꙙыꙋ́͗҃ѳѡѣꙑуешщксдꙁц]ьꙗаожгтнвмчюїъѹфхпрлб',
                shift: {3: 'ꙹⷲ҃̀͑', 12: 'Ꙍ', 21: '', 35: '', 46: ''},
                alt: {0: 'ꙅⷾⷿⷽ', 6: 'ꙹⷹ̋҇ⷴ', 13: 'ⷺⷼⷻⷷⷬⷮⷥꙷⷪⷫⷲⷳⷶⷭⷣⷢⷯіⷦⷧⷱⷤꙸⷯⷰⷡⷠⷩⷨѿ'},
                shift_alt: {
                    2: 'єѤі',
                    8: '̏͠Ѻ',
                    14: 'і',
                    16: 'є',
                    22: '',
                    27: 'ѕЅ',
                    30: 'ꙅꙄ',
                    37: 'Ꙃ',
                    39: 'ⷳⷥ',
                    44: 'ⷹ͞'
                },
                caps: {3: 'ꙹⷲ҃', 21: '', 35: ''},
                shift_caps: {3: 'ѧѩꙙыꙋ', 21: '', 35: ''}
            },
            {
                code: 'GR'
                , name: 'Ancient Greek (Polytonic)'
                , normal: '῀῎῞῍῝᾿῾`´῁῭΅῏῟̈̄ερτυθιοπᾳῃασδϕγηξκλϙʹζχψωβνμ,.ῳ'
                , shift:{0:'῀1234567890΅῏῟̈̄',19:'ϒ',36:'͵'}
                , alt:{0:'`',11:'-=',30:'ϝ',45:'·'}
                , shift_alt:{0:'~!@#$%^&*()_+'}
                , dk:{'῎':'ωὤῳᾤαἄηἤεἔᾳᾄῃᾔιἴυὔοὄ ῎','῞':'ωὥῳᾥαἅηἥεἕᾳᾅῃᾕιἵυὕοὅ ῞','῍':'ωὢαἂηἢεἒιἲυὒοὂ ῍','῝':'ωὣαἃηἣεἓιἳυὓοὃ ῝','᾿':'ωὠῳᾠαἀηἠεἐρῤᾳᾀῃᾐιἰυὐοὀ ᾿','῾':'ωὡῳᾡαἁηἡεἑρῥᾳᾁῃᾑιἱυὑοὁ ῾','`':'αὰεὲηὴιὶοὸυὺωὼᾳᾲῃῂῳῲ `','´':'αάεέηήιίοόυύωώᾳᾴῃῄῳῴ ´','῁':'ιῗυῧ ῁','῭':'ιῒυῢ ῭','΅':'ιΐυΰ ΅','῏':'αἆηἦιἶυὖωὦᾳᾆῃᾖῳᾦ ῏','̈':'ιϊυϋ ̈','̄':'αᾱιῑυῡ ̄','῀':'ωῶῳῷαᾶηῆᾳᾷῃῇιῖυῦ ῀','῟':'ωὧῳᾧαἇηἧᾳᾇῃᾗιἷυὗ ῟'}
            },
            {
                code: 'CU'
                , name: 'Glagolitic'
                , normal: 'ⱒⰷⱋⰼⱚⱛⱕⰺⱉⱙⱐⱏ※ⱑⱎⰵⱃⱅⱏⰹⱆⰹⱁⱂⱓⰶⰰⱄⰴⱇⰳⱍⰻⰽⰾ⁖ⰸⱈⱌⰲⰱⱀⰿⱔⱗⱘ'
                , shift:{0:'Ⱊ',13:'ⱜ',36:'⁙',46:'Ⱙ'}
            }
        );
}
exports.load = load
