import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LastEditedComponent} from './last-edited/last-edited.component';
import {TextEditorComponent} from './text-editor/text-editor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {MultiselectRequiredValidatorDirective} from './validators/multiselect.required.directive';
import {ButtonModule} from 'primeng/button';
import {TextSelectDirective} from './text.select.directive';
import {ActionButtonsComponent} from './action-buttons/action-buttons.component';
import {RouterModule} from '@angular/router';
import {MenuModule} from 'primeng/menu';
import {ConfirmationService} from 'primeng/api';
import {TranslateModule} from '@ngx-translate/core';
import {LanguageDropdownComponent} from './language-dropdown/language-dropdown.component';
import {DropdownModule} from 'primeng/dropdown';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {DividerModule} from 'primeng/divider';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessageModule} from 'primeng/message';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KeyboardSidebarComponent} from './keyboard-sidebar/keyboard-sidebar.component';
import {SidebarModule} from 'primeng/sidebar';
import {MessagesModule} from 'primeng/messages';
import {SkeletonModule} from 'primeng/skeleton';
import {TagModule} from 'primeng/tag';
import {PaginatorModule} from 'primeng/paginator';


@NgModule({
    declarations: [
        LastEditedComponent,
        TextEditorComponent,
        MultiselectRequiredValidatorDirective,
        TextSelectDirective,
        ActionButtonsComponent,
        LanguageDropdownComponent,
        KeyboardSidebarComponent
    ],
    exports: [
        LastEditedComponent,
        TextEditorComponent,
        MultiselectRequiredValidatorDirective,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        DropdownModule,
        CheckboxModule,
        RippleModule,
        TableModule,
        RouterModule,
        InputTextModule,
        DialogModule,
        TextSelectDirective,
        ActionButtonsComponent,
        LanguageDropdownComponent,
        DividerModule,
        MenuModule,
        FontAwesomeModule,
        ConfirmDialogModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        KeyboardSidebarComponent,
        SkeletonModule,
        TagModule,
        PaginatorModule
    ],
    imports: [
        CommonModule,
        FormsModule,
        EditorModule,
        RouterModule,
        MenuModule,
        ButtonModule,
        TranslateModule,
        DropdownModule,
        InputTextareaModule,
        SidebarModule
    ],
    providers: [ConfirmationService, {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}]
})
export class SharedModule {
}
