import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoadingService implements OnDestroy {

    private loading = new BehaviorSubject<boolean>(false);

    loading$ = this.loading.asObservable();
    private destroyed$ = new Subject();

    constructor(private router: Router) {
    }

    startLoading(): void {
        this.loading.next(true);
    }

    stopLoading(): void {
        this.loading.next(false);
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.unsubscribe();
    }
}
