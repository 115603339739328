import {provideRouter, RouterModule, Routes, withComponentInputBinding} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessDeniedComponent} from './pages/app-access-denied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppLogoutComponent} from './pages/app.logout.component';
import {AboutPageComponent} from './pages/about-page/about-page.component';
import {TranslationLabel} from './enum/translation-label';
import {OtherResourcesComponent} from './pages/other-resources/other-resources.component';
import {HomepageComponent} from './pages/homepage.component';
import {FormsComponent} from './modules/dictionary/components/forms/forms.component';
import {SourcesComponent} from './modules/dictionary/components/sources/sources.component';

const appRoutes: Routes = [
    {
        path: '', component: AppMainComponent, title: TranslationLabel.AppMenuHome,
        children: [
            {path: '', component: HomepageComponent},
            {
                path: 'corpus',
                loadChildren: () => import('./modules/corpus/corpus.module').then(m => m.CorpusModule)
            },
            {
                path: 'dictionary/sources',
                component: SourcesComponent,
                title: TranslationLabel.DictionarySourcesAbbreviations
            },
            {
                path: 'dictionary/wrdformdict',
                loadChildren: () => import('./modules/composite-dictionary/composite-dictionary.module').then(m => m.CompositeDictionaryModule)
            },
            {
                path: 'dictionary/:dictName',
                loadChildren: () => import('./modules/dictionary/dictionary.module').then(m => m.DictionaryModule)
            },
            {
                path: 'resolve-forms',
                component: FormsComponent
            },
            {
                path: 'search',
                loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
                title: TranslationLabel.SearchTitle
            },
            {
                path: 'other-resources',
                component: OtherResourcesComponent,
                title: TranslationLabel.AppMenuOtherResources
            },
            {path: 'about', component: AboutPageComponent, title: TranslationLabel.AppAboutPageBreadcrumb},
        ]
    },
    {path: 'error', component: AppErrorComponent},
    {path: 'access', component: AppAccessDeniedComponent},
    {path: 'notfound', component: AppNotfoundComponent},
    {path: 'login', component: AppLoginComponent, title: TranslationLabel.AppLoginTitle},
    {path: 'logout', component: AppLogoutComponent},
    {path: '**', redirectTo: ''},
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule],
    providers: [provideRouter(appRoutes, withComponentInputBinding()),]
})
export class AppRoutingModule {
}
