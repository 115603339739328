<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h1>{{translationLabel.DictionarySourcesAbbreviations | translate}}</h1>
            <p-table #sourcesTable [value]="sources" responsiveLayout="scroll"
                     [autoLayout]="false" [globalFilterFields]="['abbr']">
                <ng-template pTemplate="caption">
                    <div class="table-caption">
                        <h5>{{translationLabel.DictionarySourcesOldBulgarianMonuments | translate}}</h5>
                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text"
                                   (input)="sourcesTable.filterGlobal($event.target.value, 'contains')"
                                   placeholder="{{translationLabel.DictionarySourcesSearchPlaceholder | translate}}"/>
                </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th scope="col">{{translationLabel.DictionarySourcesAbbreviation | translate}}</th>
                        <th scope="col">{{translationLabel.DictionarySourcesFullName | translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-source>
                    <tr>
                        <td><strong>{{source.abbr}}</strong></td>
                        <td>{{source.full_name}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2"
                            class="empty-table-message">{{translationLabel.ErrorSearchNoResultsFound | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
