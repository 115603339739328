<div *ngIf="isLoading" class="loading-screen-wrapper">
    <p-progressSpinner [styleClass]="'custom-spinner'"></p-progressSpinner>
</div>
<div class="login-body">
    <div id="login-wrapper">
        <img src="assets/images/logo_white.png" alt="logo" style="width: 400px"/>

        <div id="login-container">
            <div class="login-title">
                <span class="title">{{ translationLabel.AppLoginTitle | translate }}</span>
                <app-language-dropdown></app-language-dropdown>
            </div>
            <form (ngSubmit)="login()" [formGroup]="loginForm">
                <div class="p-grid p-fluid" style="text-align: left;">
                    <div id="username-input" class="p-col-12 p-field p-mb-0">
                        <input type="text" class="p-inputtext large-input" name="username" autocomplete="off" pInputText
                               placeholder="{{ translationLabel.AppLoginUsernamePlaceholder | translate }}"
                               formControlName="username" required/>
                        <ng-container
                            *ngIf="loginForm.get('username').invalid && loginForm.get('username').dirty">
                            <small id="username-help"
                                   class="p-error block">{{translationLabel.ErrorMessageRequiredField | translate}}</small>
                        </ng-container>
                    </div>
                    <div class="p-field p-col-12">
                        <div class="p-password p-component p-inputwrapper p-input-icon-right">
                            <input toggle type="password" class="p-inputtext large-input" formControlName="password"
                                   name="password"
                                   autocomplete="off"
                                   placeholder="{{ translationLabel.AppLoginPasswordPlaceholder | translate }}"
                                   required/>
                        </div>
                        <ng-container
                            *ngIf="loginForm.get('password').invalid && loginForm.get('password').dirty">
                            <small id="password-help"
                                   class="p-error block">{{translationLabel.ErrorMessageRequiredField | translate}}</small>
                        </ng-container>
                    </div>
                    <div class="p-field p-col-12 p-m-0 p-pt-0 p-pb-0" *ngIf="hasErrorMessage">
                        <p-message severity="error" styleClass="p-text-left"
                                   [text]="translationLabel.AppLoginWrongCredentialsMsg | translate"></p-message>
                    </div>
                    <div class="p-col-12">
                        <p-button type="submit" label="{{ translationLabel.AppLoginButtonLabel | translate }}"
                                  icon="pi pi-user"></p-button>
                    </div>
                </div>
            </form>
        </div>
        <div class="login-footer">© {{translationLabel.AppMainFooter | translate}} 2011-{{currentYear}}</div>
    </div>
</div>
