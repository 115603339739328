import {Injectable} from '@angular/core';
import {Observable, of, shareReplay} from 'rxjs';
import {WordInterface} from '../model/word';
import {HttpClient, HttpContext} from '@angular/common/http';
import {DictionarySource} from '../model/dictionary-source';
import {environment} from '../../../../environments/environment';
import {WordInfoWrapper} from '../model/word-info';
import {CompositeWord} from '../../composite-dictionary/model/composite-word';
import {Dictionary} from '../model/dictionary';
import {map} from 'rxjs/operators';
import {IGNORED_STATUSES} from '../../../interceptor/error-http-interceptor';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService {

    private readonly dictionaries$: Observable<Dictionary[]>;

    constructor(private httpClient: HttpClient) {
        this.dictionaries$ = this.httpClient.get<Dictionary[]>(`${environment.dictUrl}/dictionaries/`).pipe(shareReplay());
    }

    listDictionaries(): Observable<Dictionary[]> {
        return this.dictionaries$;
    }

    getDictionary(shortName: string): Observable<Dictionary> {
        return this.listDictionaries()
            .pipe(
                map(dictionaries => dictionaries.find(d => d.abbr.toLowerCase() === shortName.toLowerCase()))
            );
    }

    translateDictionaryName(shortName: string, lang: string): Observable<string> {
        return this.getDictionary(shortName).pipe(map(dictionary => {
            if (lang === 'bg') {
                return dictionary.full_name;
            } else return dictionary.full_name_en;
        }));
    }

    addWord(input: any): Observable<any> {
        return this.httpClient.post(`${environment.dictUrl}/dict-html/`, input, {
            context: new HttpContext().set(IGNORED_STATUSES, [400]),
        });
    }

    editWord(wordEntry: any, entryId: string): Observable<any> {
        return this.httpClient.patch(`${environment.dictUrl}/dict-html/${entryId}/`, wordEntry, {
            context: new HttpContext().set(IGNORED_STATUSES, [400]),
        });
    }

    getCompositeWord(wordId: string): Observable<CompositeWord> {
        // TODO
        return of({
            entryId: wordId,
            lemma: 'аватарь',
            entry: 'авиатарь'
        });
    }

    getWord(wordId: string): Observable<WordInterface> {
        return this.httpClient.get<WordInterface>(`${environment.dictUrl}/dict/${wordId}/`);
    }

    getWordHtml(wordId: string) {
        return this.httpClient.get(`${environment.dictUrl}/dict-html/${wordId}/?format=html`, {responseType: 'text'});
    }

    unpublish(entryId: string): Observable<any> {
        return this.httpClient.patch(`${environment.dictUrl}/dict-html/${entryId}/`, {published: false});
    }

    publish(entryId: string): Observable<any> {
        return this.httpClient.patch(`${environment.dictUrl}/dict-html/${entryId}/`, {published: true});
    }

    delete(entryId: string): Observable<any> {
        return this.httpClient.delete(`${environment.dictUrl}/dict-html/${entryId}/`);
    }

    searchWords(searchTerm: string, page = 1, dictionaryId?: number | string, published?: boolean): Observable<WordInfoWrapper> {
        let query = `?page=${page}&dictionary=${dictionaryId}&published=${published}`;
        if (searchTerm) {
            query = `${query}&search=${searchTerm}`;
        }
        return this.httpClient.get<WordInfoWrapper>(`${environment.dictUrl}/dict/${query}`, {
            context: new HttpContext().set(IGNORED_STATUSES, [404]),
        });
    }

    getSources(): Observable<DictionarySource[]> {
        return this.httpClient.get<DictionarySource[]>(`${environment.dictUrl}/sources/`);
    }

    lookupWord(searchTerm: string, dictionaryId = 1): Observable<WordInfoWrapper> {
        let query = `?search=${searchTerm}`;
        if (dictionaryId) {
            query = `${query}&dictionary=${dictionaryId}`;
        }
        return this.httpClient.get<WordInfoWrapper>(`${environment.dictUrl}/dict/${query}`);
    }

    getDictionaryDetail(dictionaryId: number | string): Observable<Dictionary> {
        return this.httpClient.get<Dictionary>(`${environment.dictUrl}/dictionaries/${dictionaryId}/`);
    }

    saveIntroPage(dictionaryId: number | string, input: string): Observable<any> {
        return this.httpClient.patch(`${environment.dictUrl}/dictionaries/${dictionaryId}/`, {annotation: input});
    }

    // TODO get from backend
    getAffixes(affixType: string): Observable<string[]> {
        return of(['на', 'под']);
    }
}
