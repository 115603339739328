import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {CorpusService} from '../modules/corpus/service/corpus.service';
import {BreadcrumbService} from '../app.breadcrumb.service';
import {TranslationLabel} from '../enum/translation-label';
import {TranslateService} from '@ngx-translate/core';
import {Nomenclature} from '../modules/corpus/model/nomenclature';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['homepage.component.scss', '../modules/corpus/corpus.scss']
})
export class HomepageComponent implements OnInit {

    genres: Nomenclature[];
    translationLabel: typeof TranslationLabel = TranslationLabel;
    loading: boolean;

    constructor(public userService: UserService,
                private corpusService: CorpusService,
                private breadcrumbService: BreadcrumbService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.translateService.stream(TranslationLabel.AppMenuHome)
            .subscribe(translation => this.breadcrumbService.setItems([{label: translation}]));
        this.loading = true;
        this.corpusService.getGenres().subscribe(genres => {
            this.genres = genres;
            this.loading = false;
        });
    }

}
