import {TranslateService} from '@ngx-translate/core';
import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';
import {catchError} from 'rxjs/operators';
import {TranslationLabel} from '../enum/translation-label';
import {Constants} from '../constants';
import {Router} from '@angular/router';

export const IGNORED_STATUSES = new HttpContextToken<number[]>(() => []);

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

    constructor(private messageService: MessageService,
                private translateService: TranslateService,
                private router: Router
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const ignoredStatuses = request.context.get(IGNORED_STATUSES);

        // skip handling of translation file requests
        if (request.url.startsWith(Constants.TRANSLATIONS_PATH)) {
            return next.handle(request);
        } else {
            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 403 || error.status === 401) {
                        if (this.router.url.includes('login')) {
                            return throwError(() => error);
                        } else {
                            this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
                        }
                    }

                    if (ignoredStatuses.includes(error.status)) {
                        return throwError(() => error);
                    }

                    if (error.status === 404) {
                        this.router.navigateByUrl('/notfound');
                    }
                    return this.translateService
                        .get([TranslationLabel.ErrorHttpErrorTitle, TranslationLabel.ErrorHttpErrorMessage])
                        .pipe(
                            tap(translations => {
                                this.messageService.add({
                                    key: 'http-error',
                                    severity: 'error',
                                    summary: translations[TranslationLabel.ErrorHttpErrorTitle],
                                    detail: translations[TranslationLabel.ErrorHttpErrorMessage]
                                });
                            })
                        );
                })
            );
        }
    }
}
