import {Component} from '@angular/core';
import {Language} from '../../../model/language';
import {Constants} from '../../../constants';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../services/language.service';

@Component({
    selector: 'app-language-dropdown',
    templateUrl: './language-dropdown.component.html'
})
export class LanguageDropdownComponent {
    readonly languages: Language[] = [
        {name: 'BG', code: Constants.LANGUAGE_BG},
        {name: 'EN', code: Constants.LANGUAGE_EN},
    ];

    selectedLanguage: Language;

    constructor(private translateService: TranslateService, private languageService: LanguageService) {
        this.selectedLanguage = this.languages.find(language => language.code === languageService.getLanguage());
    }

    changeLanguage() {
        this.translateService.use(this.selectedLanguage.code);
        this.languageService.setLanguage(this.selectedLanguage.code);
    }
}
