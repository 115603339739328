import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class KeyboardService {

    visibility = false;
    toggleKeyboard = new BehaviorSubject<{ visibility: boolean; inputId: string }>({visibility: false, inputId: ''});

    toggleKeyboard$ = this.toggleKeyboard.asObservable();

    constructor() {
    }

    showKeyboard(inputId: string): void {
        this.visibility = true;
        this.toggleKeyboard.next({visibility: this.visibility, inputId});
    }

    toggleKeyboardVisibility(inputId: string): void {
        this.visibility = !this.visibility;
        this.toggleKeyboard.next({visibility: this.visibility, inputId});
    }

    hideKeyboard(): void {
        this.visibility = false;
        this.toggleKeyboard.next({visibility: this.visibility, inputId: ''});
    }
}
