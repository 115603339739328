import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';

@Component({
    selector: 'app-logout',
    template: ''
})
export class AppLogoutComponent implements OnInit {

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.userService.logout();
    }

}
