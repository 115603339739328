import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

export class DefaultTranslateHttpLoader extends TranslateHttpLoader {

    defaultLocale: string;

    constructor(defaultLocale: string, http: HttpClient, prefix?: string, suffix?: string) {
        super(http, prefix, suffix);
        this.defaultLocale = defaultLocale;
    }

    getTranslation(lang: string): Observable<object> {
        return super.getTranslation(lang).pipe(
            catchError(() => {
                console.log(`Locale ${lang} not supproted, using default ${this.defaultLocale}`);
                return super.getTranslation(this.defaultLocale).pipe(catchError(error => of({error})));
            })
        );
    }
}
