/* eslint-disable no-underscore-dangle */
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Text} from '../model/text';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {delay, map} from 'rxjs/operators';
import {Constants} from '../../../constants';
import {Page} from '../model/page';
import {PageInfo} from '../model/pages-list';
import {TextList} from '../model/text-list';
import {PageUpdateRequest} from '../model/page-update-request';
import {Transcripts} from '../model/transcripts';
import {Contents} from '../model/contents-table';
import {Nomenclature} from '../model/nomenclature';

export interface CorpusLoadEvent {
    pageNo: number;
    pageSize: number;
    published?: boolean;
    filter?: string;
    genre?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CorpusService {

    constructor(private httpClient: HttpClient) {
    }

    runMorphologicalAnalysis(docId: string, pageNum: number, pageId: string): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/xltexts/${docId}/pages/${pageNum}/${pageId}/regenerate-morpho/`);
    }

    runMorphologicalAnalysisAi(docId: string, pageNum: number, pageId: string): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/xltexts/${docId}/pages/${pageNum}/${pageId}/regenerate-morpho-ai/`);
    }

    getTexts(event: CorpusLoadEvent): Observable<TextList> {
        const params = new HttpParams()
            .set('page', event.pageNo)
            .set('page_size', event.pageSize)
            .set('published', event.published || '')
            .set('search', event.filter || '')
            .set('genre', event.genre || '');
        return this.httpClient.get<TextList>(`${environment.apiUrl}/xltexts/?` + params.toString());
    }

    getGenres(): Observable<Nomenclature[]> {
        return this.httpClient.get<Nomenclature[]>(`${environment.apiUrl}/listdescription/?type=genre`);
    }

    getSpelling(): Observable<Nomenclature[]> {
        return this.httpClient.get<Nomenclature[]>(`${environment.apiUrl}/listdescription/?type=spelling`);
    }

    unpublish(text: Text): Observable<Text> {
        return this.changePublishStatus(text, false);
    }

    publish(text: Text): Observable<Text> {
        return this.changePublishStatus(text, true);
    }

    delete(docId: string): Observable<any> {
        return this.httpClient.delete<any>(`${environment.apiUrl}/xltexts/${docId}/`);
    }

    getHistoryTextInfo(docId: string, historyId: number): Observable<Text> {
        return this.httpClient.get<any>(`${environment.apiUrl}/xltexts/${docId}/history/${historyId}/`);
    }

    getTextInfo(docId: string): Observable<Text> {
        return this.httpClient.get<any>(`${environment.apiUrl}/xltexts/${docId}/`);
    }

    getTranscripts(transcriptId: string) {
        return this.httpClient.get<any>(`${environment.apiUrl}/xltranscripts/${transcriptId}/`);
    }

    updateTranscripts(transcripts: Transcripts): Observable<any> {
        return this.httpClient.put<any>(`${environment.apiUrl}/xltranscripts/${transcripts.id}/`, transcripts);
    }

    createTranscripts(transcripts: Transcripts): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/xltranscripts/`, transcripts);
    }

    deleteTranscripts(transcripts: Transcripts): Observable<any> {
        return this.httpClient.delete<any>(`${environment.apiUrl}/xltranscripts/${transcripts.id}/`);
    }

    transformTranscripts(transcript: Transcripts): Record<string, string> {
        const transcriptMap: Record<string, string> = {};
        transcript?.data?.body?.div?.[0]?.bibl?.forEach(bibl => {
            const key = bibl.abbr[0].value;
            transcriptMap[key] = bibl.title[0].value;
        });
        transcriptMap.title = transcript.data.body.div[0].desc[0].hi[0].value;
        return transcriptMap;
    }

    getTextContents(docId: string): Observable<Contents> {
        return this.httpClient.get<Contents>(`${environment.apiUrl}/xltexts/${docId}/contents/`);
    }

    refreshTextContents(docId: string): Observable<any> {
        return this.httpClient.get<any>(`${environment.apiUrl}/xltexts/${docId}/contents/regenerate/`);
    }

    getPagesInfo(docId: string): Observable<PageInfo[]> {
        return this.httpClient.get<PageInfo[]>(`${environment.apiUrl}/xltexts/${docId}/pages/`);
    }

    getWordDocument(docId: string): Observable<Blob> {
        return this.httpClient.get(`${environment.apiUrl}/xltextdocx/${docId}/?format=docx`, {responseType: 'blob'});
    }

    getXMLDocument(docId: string): Observable<Blob> {
        return this.httpClient.get(`${environment.apiUrl}/xltextxml/${docId}/`, {responseType: 'blob'});
    }

    getJSONDocument(docId: string): Observable<string> {
        return of(`
            {
              "TEI": {
                "teiHeader": {
                  "fileDesc": {
                    "titleStmt": {
                      "title": [
                        "Хроника на Георги Амартол",
                        "Хроника на Георги Амартол",
                        "Chronicon Georgi Amartol"
                      ],
                      "author": "Георги Амартол"
                    },
                    "extent": ".",
                    "publicationStmt": {
                      "authority": "",
                      "date": ""
                    },
                    "sourceDesc": {
                      "bibl": [
                        "",
                        ""
                      ],
                      "msDesc": {
                        "msIdentifier": {
                          "repository": "",
                          "idno": ""
                        },
                        "msContents": {
                          "msItem": {
                            "title": ".",
                            "editor": "",
                            "textLang": "Руски"
                          }
                        },
                        "history": {
                          "origin": {
                            "date": [
                              ".",
                              "",
                              ""
                            ]
                          }
                        }
                      }
                    }
                  },
                  "profileDesc": {
                    "textClass": {
                      "classCode": "Историческа компилация"
                    }
                  },
                  "encodingDesc": {
                    "editorialDecl": {
                      "normalization": {
                        "p": ""
                      }
                    }
                  },
                  "revisionDesc": {
                    "change": {
                      "idno": "1.0.5-dev"
                    }
                  }
                },
                "text": {
                  "body": {
                    "div": [
                      {
                        "p": [
                          {
                            "w": [
                              "[бел.10",
                              "]",
                              "нечтⷭꙑи",
                              "тꙑи",
                              "ѡбꙑчаи",
                              "потоⷨ",
                              "ꙗко",
                              "ꙁакона",
                              "хранѧхѹ",
                              "повелѣнимь",
                              "мчтлвꙑмь"
                            ],
                            "add": "**",
                            "anchor": [
                              "",
                              "",
                              "",
                              "",
                              "",
                              "",
                              ""
                            ],
                            "pc": ","
                          },
                          {
                            "w": [
                              "слѹжахѹ",
                              "кѹмиромъ",
                              "и",
                              "се",
                              "бꙑⷭ",
                              "житимь",
                              "въ",
                              "дѣло",
                              "ꙗко",
                              "ли",
                              "напасти",
                              "ли"
                            ],
                            "pc": [
                              ",",
                              "||",
                              ","
                            ],
                            "add": "(40б)"
                          }
                        ]
                      },
                      {
                        "note": [
                          {
                            "#text": "3 πάν",
                            "quote": [
                              "ta 15. –",
                              "4. гре",
                              "ai 16. – 22 ⸱ѡч",
                              "і⸱ въ S; въ ориг. стерто; г",
                              "ре",
                              "i&gt; 6.&#13;"
                            ]
                          },
                          {
                            "quote": [
                              2,
                              ")  1 памѧтѹеть. – 2 н",
                              2,
                              "2 гре",
                              24,
                              "7 такъ",
                              "въ",
                              "S; въ ориг. стерто; гре",
                              6,
                              "14 „ѿ тѣхъ” (S)",
                              5,
                              "5) до",
                              "б. „сѣмѧ”"
                            ]
                          }
                        ]
                      }
                    ]
                  }
                }
              }
            }
        `);
    }

    getTextReport(docId: string): Observable<string> {
        return of('');
    }

    editTextInfo(text: Text): Observable<Text> {
        return this.httpClient.put<Text>(`${environment.apiUrl}/xltexts/${text.docId}/`, text);
    }

    addText(text: Text): Observable<Text> {
        return this.httpClient.post<Text>(`${environment.apiUrl}/xltexts/`, text);
    }

    getAnnotationTypes(): Observable<{ label: string; type: string }[]> {
        return of([
            {label: 'Бележка', type: Constants.ANNOTATION_TYPE_NOTE},
            {label: 'Разночетене', type: Constants.ANNOTATION_TYPE_DIFFERENT_READING},
            {label: 'Бележка под черта', type: Constants.ANNOTATION_TYPE_FOOTNOTE},
            {label: 'Червенопис', type: Constants.ANNOTATION_TYPE_REDSCRIPT},
            {label: 'Библейски цитат', type: Constants.ANNOTATION_TYPE_HIGHLIGHT},
            {label: 'Обикновен текст', type: Constants.ANNOTATION_TYPE_HIGHLIGHT},
        ]);
    }

    importText(file: File): Observable<string> {
        return of('doc_5').pipe(delay(5000)); // TODO return docId of newly created document
    }

    getPageByName(docId: string, searchString: string): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/xltexts/${docId}/pageByName/?n=${searchString}`);
    }

    getFullPage(docId: number, pageNum: number, pageId: number): Observable<Page> {
        return this.httpClient.get<any>(`${environment.apiUrl}/xltexts/${docId}/pagesExt/${pageNum}/${pageId}/`)
            .pipe(
                map(this.pageMapping())
            );
    }

    getFullPageVersion(docId: number, pageNum: number, pageId: number, historyId: number): Observable<Page> {
        return this.httpClient.get<any>(`${environment.apiUrl}/xltexts/${docId}/pages/${pageNum}/${pageId}/history/${historyId}/`)
            .pipe(
                map(this.pageMapping())
            );
    }

    pageMapping() {
        return page => {
            const div = page?.data?.body?.div;
            page.data.body.endnotes = div.filter(obj => obj.type_ === 'endnotes');
            page.data.body.footnotes = div.filter(obj => obj.type_ === 'footnotes');
            page.data.body.page = div.filter(obj => obj.type_ === 'page' || obj.type_ === 'part');

            page.data.body?.footnotes?.[0].note?.forEach(note => {
                note.type = note.type_;
                note.start = note.start_;
                note.data?.forEach(data => data.seg?.forEach(segment => segment.lang = segment.__XML__lang_));
            });
            page.data.body?.endnotes?.[0]?.note?.forEach(note => {
                note.type = note.type_;
                note.start = note.start_;
                note.end = note.end_;
                note.startWordIndex = note.start_word_idx_ ?? 1;
                note.endWordIndex = note.end_word_idx_;
                note.data?.forEach(data => data.seg?.forEach(segment => segment.lang = segment.__XML__lang_));
            });

            return page;
        };
    }

    editPage(docId: string, pageId: string, page: PageUpdateRequest): Observable<any> {
        return this.httpClient.patch(`${environment.apiUrl}/xltexts/${docId}/pages/${page.pageNum}/${pageId}/`, page);
    }

    addNewPage(docId: string, page: PageUpdateRequest): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/xltexts/${docId}/pages/`, page);
    }

    deletePage(docId: string | number, pageId: string, pageNum: number): Observable<any> {
        return this.httpClient.delete(`${environment.apiUrl}/xltexts/${docId}/pages/${pageNum}/${pageId}/`);
    }

    private changePublishStatus(text: Text, isPublished: boolean): Observable<Text> {
        const newText = {...text};
        newText.published = isPublished;
        return this.editTextInfo(newText);
    }
}
