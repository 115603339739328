import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from './constants';
import {LanguageService} from './services/language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    menuMode = 'static';

    lightMenu = false;

    inputStyle = 'outlined';

    ripple: boolean;

    title = 'Histdict';

    constructor(translateService: TranslateService, languageService: LanguageService) {
        translateService.addLangs([Constants.LANGUAGE_BG, Constants.LANGUAGE_EN]);
        // this language will be used as a fallback when a translation isn't found in the current language
        translateService.setDefaultLang(Constants.LANGUAGE_BG);
        translateService.use(languageService.getLanguage());
    }
}
