import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy, NgOptimizedImage} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {BreadcrumbModule} from 'primeng/breadcrumb';

import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessDeniedComponent} from './pages/app-access-denied.component';
import {AppLoginComponent} from './pages/app.login.component';

import {BreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import {MessageModule} from 'primeng/message';
import {ToggleDirective} from './directive/toggle.directive';
import {AppLogoutComponent} from './pages/app.logout.component';
import {SpinnerHttpInterceptor} from './interceptor/spinner-http-interceptor.service';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToastModule} from 'primeng/toast';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {httpTranslateLoaderFactory} from './utils/http-translate-loader-factory';
import {DropdownModule} from 'primeng/dropdown';
import {LanguageService} from './services/language.service';
import {AboutPageComponent} from './pages/about-page/about-page.component';
import {TimelineModule} from 'primeng/timeline';
import {SharedModule} from './modules/shared/shared.module';
import {MessageService} from 'primeng/api';
import {ErrorHttpInterceptor} from './interceptor/error-http-interceptor';
import {TitleStrategy} from '@angular/router';
import {TemplatePageTitleStrategy} from './utils/template-title-strategy';
import {UserService} from './services/user.service';
import {OtherResourcesComponent} from './pages/other-resources/other-resources.component';
import {HomepageComponent} from './pages/homepage.component';
import {AuthHttpInterceptor} from './interceptor/auth-http-interceptor';

export const interceptorProviders =
    [
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    ];

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BreadcrumbModule,
        MessageModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        ToastModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DropdownModule,
        TimelineModule,
        SharedModule,
        NgOptimizedImage
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessDeniedComponent,
        ToggleDirective,
        AppLogoutComponent,
        AboutPageComponent,
        OtherResourcesComponent,
        HomepageComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService,
        BreadcrumbService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHttpInterceptor,
            multi: true
        },
        interceptorProviders,
        LanguageService,
        MessageService,
        UserService,
        {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
        {provide: 'googleTagManagerId', useValue: 'GTM-TMZ49RJZ'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
