import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor() {
    }

    search(term: string, target: string[]): Observable<any> {
        return of([
            {
                type: 'histdict',
                results: [
                    {entryId: 'd_00011', entry: 'аватарь', context: 'аватарь ꙗ м ЛИ Авиатар — първ', rank: 2},
                    {entryId: 'd_10874', entry: 'ѣвавафаръ', context: 'ѣвавафаръ [погр. З Мк 2.26] вж', rank: 2},
                ]
            },
            {
                type: 'corpus',
                results: [
                    {
                        docId: 'doc_162',
                        title: 'Германов сборник Част 2 (слово 16-28)',
                        context: 'аватарь ꙗ м ЛИ Авиатар — първ',
                        rank: 2
                    }
                ]
            }
        ]);
    }

    searchForms(word: string, searchEverywhere?: boolean): Observable<any> {
        return of({
            dictionaryResults: [
                {
                    entryId: 'd_00011',
                    entry: 'аватарь',
                    form: 'аватарь',
                    conjugation: '[Nmos] Съществително име, мъжки род, именителен падеж, ед.ч.'
                },
                {
                    entryId: 'd_00011',
                    entry: 'аватарь',
                    form: 'аватарь',
                    conjugation: '[Nmas] Съществително име, мъжки род, винителен падеж, ед.ч.'
                },
            ],
            allForms: [
                {tag: 'Nmos', form: 'аватарь', tagTitle: 'Съществително име, мъжки род, именителен падеж, ед.ч.'},
                {tag: 'Nmgs', form: 'аватарꙗ, аватарѣ, аватара'},
                {
                    tag: 'Nmas',
                    form: 'аватарь, аватаръ',
                    tagTitle: 'Съществително име, мъжки род, винителен падеж, ед.ч.'
                },
            ]
        });
    }
}
