<p-dropdown class="language-dropdown" [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="name" (onChange)="changeLanguage()">
    <ng-template pTemplate="selectedItem">
        <div class="language-item language-item-value"  *ngIf="selectedLanguage">
            <div>{{selectedLanguage.name}}</div>
        </div>
    </ng-template>
    <ng-template let-language pTemplate="item">
        <div class="language-item">
            <div>{{language.name}}</div>
        </div>
    </ng-template>
</p-dropdown>
