<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="content_title">
                <h1>{{translationLabel.AppAboutPageTitle | translate}}</h1>
                <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
                    <ng-template pTemplate="content" let-event>
                        <div *ngIf="event.images" class="image-container">
                            <img *ngFor="let image of event.images" [src]="image.src"
                                 [ngStyle]="{'height': image.height}" alt="event.title"/>
                        </div>
                        <h5 *ngIf="event.title">{{event.title}}</h5>
                        <span style="display: block; margin-top: 8px; margin-bottom: 8px;">{{event.subtitle}}</span>
                    </ng-template>
                    <ng-template pTemplate="opposite" let-event>
                        {{event.year}}
                    </ng-template>
                </p-timeline>
            </div>
        </div>

        <div class="card">
            <div class="logo-container">
                <img src="assets/images/logo_esf.gif" alt="ESF Logo"
                     style="position: relative; height:36px;" class="p-mr-3"/>
                <img src="assets/images/bg-flag.jpg" alt="BG Flag"
                     style="position: relative;  height:32px;" class="p-mx-3"/>
                <img src="assets/images/eu-logo.jpg" alt="EU Logo"
                     style="position: relative; height:36px;" class="p-ml-3"/>
            </div>
            <div class="p-mt-3">
                <p>{{translationLabel.AppAboutPageFinanceParagraph | translate}}</p>
            </div>
        </div>

        <div class="card">
            <h5>{{translationLabel.AppAboutPageProjectObjective | translate}}</h5>
            <div style="margin-top: 1rem;">
                <p>{{translationLabel.AppAboutPageObjectiveParagraph1 | translate}}</p>
                <p>{{translationLabel.AppAboutPageObjectiveParagraph2 | translate}}</p>
            </div>
        </div>
    </div>
</div>
