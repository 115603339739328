import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from '../services/loading.service';
import {Constants} from '../constants';

@Injectable()
export class SpinnerHttpInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(private loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(Constants.TRANSLATIONS_PATH)) {
            return next.handle(req);
        } else {
            this.totalRequests++;
            this.loadingService.startLoading();
            return next.handle(req)
                .pipe(
                    finalize(() => {
                        this.totalRequests--;
                        if (this.totalRequests === 0) {
                            this.loadingService.stopLoading();
                        }
                    })
                );
        }
    }
}
