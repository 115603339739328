// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    tinyMCEApiKey: '6921pm92jtk6zq66rx5l5jii77wko4jc93z66vjnfkfyh9bz',
    apiUrl: '/backend/textcorpus/api',
    morphoUrl: '/backend/morpho/api',
    dictUrl: '/backend/dictionary/api',
    tokenUrl: '/backend/api/token'
};
