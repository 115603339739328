<div *ngIf="loadingService.loading$ | async" class="loading-screen-wrapper">
    <p-progressSpinner [styleClass]="'custom-spinner'"></p-progressSpinner>
</div>

<div class="exception-body">
    <div class="exception-wrapper notfound">
        <div class="logo" routerLink="/" style="outline: none; cursor: pointer;">
            <img [src]="'assets/images/logo_white.png'" alt="" style="width: 400px; padding: 0 1em;">
        </div>

        <h1 id="error-code">404</h1>

        <p class="message-title">{{translationLabel.PageNotFoundTitle | translate}}</p>
        <p class="message-summary">{{translationLabel.PageNotFoundDetail | translate}}</p>

        <a href="/">
            <i class="pi pi-home"></i>
            <span>Dashboard</span>
        </a>

        <div class="footer">
            © {{translationLabel.AppMainFooter | translate}} 2011-{{currentYear}}
        </div>
    </div>
</div>
