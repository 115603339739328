import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../../app.breadcrumb.service';
import {TranslationLabel} from '../../../../enum/translation-label';
import {TranslateService} from '@ngx-translate/core';
import {DictionaryService} from '../../service/dictionary.service';
import {DictionarySource} from '../../model/dictionary-source';

@Component({
    selector: 'app-sources',
    templateUrl: './sources.component.html',
    styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {

    sources: DictionarySource[] = [];

    translationLabel: typeof TranslationLabel = TranslationLabel;

    constructor(private breadcrumbService: BreadcrumbService,
                private translateService: TranslateService,
                public dictionaryService: DictionaryService) {
        translateService.stream(TranslationLabel.AppMenuHistoricalDict).subscribe(translation =>
            this.breadcrumbService.setItems([
                {label: translation}
            ])
        );
    }

    ngOnInit() {
        this.dictionaryService.getSources().subscribe(sources => this.sources = sources);
    }
}
