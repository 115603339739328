import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient,} from '@angular/common/http';
import {AccessToken, AuthToken} from '../model/auth-token';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public user: Observable<string>;
    private userSubject: BehaviorSubject<string>;

    constructor(private router: Router,
                private httpClient: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<string>(localStorage.getItem('userId'));
        this.user = this.userSubject.asObservable();
    }

    login(username, password): Observable<AuthToken> {
        const payload = {username, password};
        return this.httpClient.post<AuthToken>(`${environment.tokenUrl}/`, payload)
            .pipe(
                tap(token => {
                    const parsedPayload = this.parseToken(token.token);
                    this.setUserData(token, parsedPayload);
                })
            );
    }

    parseToken(token: string): AccessToken {
        const decodedPayload = atob(token.split('.')[1]);
        return JSON.parse(decodedPayload);
    }

    setUserData(token: AuthToken, tokenObject) {
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('userId', tokenObject.user_id);
        this.userSubject.next(tokenObject.user_id);
    }

    refreshToken(refreshToken: string): Observable<AuthToken> {
        return this.httpClient.post<AuthToken>(`${environment.tokenUrl}/refresh/`, {token: refreshToken})
            .pipe(
                tap(newToken => {
                    console.log('Refreshing access token');
                    this.updateAccessToken(newToken);
                })
            );
    }

    updateAccessToken(newAccessToken: AuthToken) {
        const currentToken: AuthToken = JSON.parse(localStorage.getItem('token'));
        currentToken.token = newAccessToken.token;
        localStorage.setItem('token', JSON.stringify(currentToken));
    }

    logout(): void {
        const tokenString = localStorage.getItem('token');
        if (tokenString) {
            const token = JSON.parse(tokenString);
            this.httpClient.post(`${environment.tokenUrl}/blacklist/`, {refresh: token.token})
                .subscribe(() => {
                    // remove user from local storage and set current user to null
                    this.clearUserData();
                    this.router.navigate(['/']);
                });
        }
    }

    clearUserData() {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.userSubject.next(null);
    }

    isLoggedIn(): boolean {
        return this.userSubject.value != null;
    }
}
