import {Component} from '@angular/core';
import {BreadcrumbService} from '../../app.breadcrumb.service';
import {TranslationLabel} from '../../enum/translation-label';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-other-resources',
    templateUrl: './other-resources.component.html',
    styleUrls: ['./other-resources.component.scss']
})
export class OtherResourcesComponent {

    events: any[];
    translationLabel: typeof TranslationLabel = TranslationLabel;

    constructor(private breadcrumbService: BreadcrumbService, private translateService: TranslateService) {
        this.translateService.stream(TranslationLabel.AppMenuOtherResources).subscribe(translation =>
            this.breadcrumbService.setItems([
                {label: translation}
            ])
        );
    }
}
