import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private static readonly LANGUAGE_KEY = 'language';

    constructor(private translateService: TranslateService) {
    }

    setLanguage(language: string) {
        localStorage.setItem(LanguageService.LANGUAGE_KEY, language);
    }

    getLanguage(): string {
        let language = localStorage.getItem(LanguageService.LANGUAGE_KEY);
        if (!language) {
            language = this.translateService.getDefaultLang();
            this.setLanguage(language);
        }
        return language;
    }
}
