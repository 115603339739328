import {Component, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../app.breadcrumb.service';
import {TranslationLabel} from '../../enum/translation-label';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    events: any[];
    translationLabel: typeof TranslationLabel = TranslationLabel;

    constructor(private breadcrumbService: BreadcrumbService, private translateService: TranslateService) {
        this.translateService.stream(TranslationLabel.AppAboutPageBreadcrumb).subscribe(translation =>
            this.breadcrumbService.setItems([
                {label: translation}
            ])
        );
    }

    ngOnInit() {
        this.translateService.stream([
            TranslationLabel.AppAboutPageProject1Title,
            TranslationLabel.AppAboutPageProject1Subtitle,
            TranslationLabel.AppAboutPageProject2Title,
            TranslationLabel.AppAboutPageProject2Subtitle,
            TranslationLabel.AppAboutPageProject3Title,
            TranslationLabel.AppAboutPageProject3Subtitle,
            TranslationLabel.AppAboutPageProject4Title,
            TranslationLabel.AppAboutPageProject4Subtitle,
            TranslationLabel.AppAboutPageProject5Title,
            TranslationLabel.AppAboutPageProject5Subtitle
        ]).subscribe(translations => {
            this.events = [
                {
                    title: translations[TranslationLabel.AppAboutPageProject1Title],
                    year: '2022',
                    subtitle: translations[TranslationLabel.AppAboutPageProject1Subtitle],
                    images: [
                        {src: 'assets/images/ESF.png', height: '60px'},
                        {
                            src: 'assets/images/logo-H.png',
                            height: '65px'
                        },
                        {
                            src: 'assets/images/logo-bg-right-no-back.png',
                            height: '60px'
                        },
                    ]
                },
                {
                    title: translations[TranslationLabel.AppAboutPageProject2Title],
                    year: '2018',
                    subtitle: translations[TranslationLabel.AppAboutPageProject2Subtitle],
                    images: [{src: 'assets/images/mpm-logo-350x78.png'}, {
                        src: 'assets/images/logo-bg-right-no-back.png',
                        height: '60px'
                    }, {src: 'assets/images/ESF.png', height: '60px'}]
                },
                {
                    title: translations[TranslationLabel.AppAboutPageProject3Title],
                    year: '2016',
                    subtitle: translations[TranslationLabel.AppAboutPageProject3Subtitle],
                    images: [{src: 'assets/images/ukh_logo.png'}, {src: 'assets/images/fni_bg.png'}]
                },
                {
                    title: translations[TranslationLabel.AppAboutPageProject4Title],
                    year: '2012',
                    subtitle: translations[TranslationLabel.AppAboutPageProject4Subtitle],
                    images: [{src: 'assets/images/project-logo2.gif'}]
                },
                {
                    title: translations[TranslationLabel.AppAboutPageProject5Title],
                    year: '2011',
                    subtitle: translations[TranslationLabel.AppAboutPageProject5Subtitle],
                    images: [{src: 'assets/images/project-logo.gif'}]
                }
            ];
        });
    }

}
