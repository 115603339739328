import {Component, OnDestroy, OnInit} from '@angular/core';
import * as VKL from 'virtual-keyboard/vk_loader';
import * as VK from 'virtual-keyboard/virtualkeyboard';
import {KeyboardService} from '../keyboard.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-keyboard-sidebar',
    templateUrl: './keyboard-sidebar.component.html',
    styleUrls: ['./keyboard-sidebar.component.scss']
})
export class KeyboardSidebarComponent implements OnInit, OnDestroy {

    visible: any;
    inputId: string;
    subscription: Subscription;

    constructor(private keyboardService: KeyboardService) {
    }

    ngOnInit(): void {
        VKL.load();

        this.subscription = this.keyboardService.toggleKeyboard$.subscribe(event => {
            if (!event.visibility) {
                VK.VirtualKeyboard.hide();
            }
            this.visible = event.visibility;
            this.inputId = event.inputId;
        });
    }

    attachKeyboard() {
        VK.VirtualKeyboard.attachInput(document.getElementById(this.inputId) as HTMLInputElement);
        VK.VirtualKeyboard.show(this.inputId, 'id_kbd');
    }

    hideKeyboard() {
        VK.VirtualKeyboard.hide();
        // update state in service so toggle can work properly
        this.keyboardService.hideKeyboard();
    }

    ngOnDestroy(): void {
        VK.VirtualKeyboard.detachInput();
        this.subscription.unsubscribe();
    }
}
