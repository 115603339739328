export class Constants {
    public static readonly LANGUAGE_EN = 'en';
    public static readonly LANGUAGE_BG = 'bg';
    public static readonly TRANSLATIONS_PATH = '/assets/i18n/';

    public static readonly ANNOTATION_TYPE_NOTE = 'c';
    public static readonly ANNOTATION_TYPE_DIFFERENT_READING = 'd';
    public static readonly ANNOTATION_TYPE_REDSCRIPT = 'r';
    public static readonly ANNOTATION_TYPE_HIGHLIGHT = 'h';
    public static readonly ANNOTATION_TYPE_FOOTNOTE = 'f';
    public static readonly ANNOTATION_TYPE_NONE = 'n';
    public static readonly ANNOTATION_TYPE_MORPHOLOGICAL_ANALYSIS = 'morph';

    public static readonly PUNCTUATION_TYPE = 'punct';
}
