<div *ngIf="isLoading" class="loading-screen-wrapper">
    <p-progressSpinner [styleClass]="'custom-spinner'"></p-progressSpinner>
</div>
<div class="wrapper" [ngClass]="{'menu-layout-overlay':isOVerlay(),
                                'menu-layout-static':isStatic(),
                                'layout-light':app.lightMenu,
                                'sidebar-inactive-l':menuInactiveDesktop,
                                'sidebar-active-m':menuActiveMobile,
                                'p-ripple-disabled': !app.ripple, 'p-input-filled': app.inputStyle === 'filled'}">
    <app-topbar></app-topbar>

    <div class="sidebar" (click)="onMenuClick()">
        <div class="sidebar-scroll-content">
            <div class="logo" routerLink="/" style="outline: none; cursor: pointer;">
                <img [src]="'assets/images/logo_white.png'" alt="" style="width: 265px; padding: 0 1em;">
            </div>
            <app-menu></app-menu>
        </div>
    </div>
    <p-toast key="http-error"></p-toast>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
    <div class="footer">
        © {{translationLabel.AppMainFooter | translate}} 2011-{{currentYear}}
    </div>
</div>
