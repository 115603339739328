import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {UserService} from '../services/user.service';
import {AuthToken} from '../model/auth-token';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // skip intercepting of translation file requests or token requests
        if (request.url.startsWith(Constants.TRANSLATIONS_PATH) || request.url.includes('token')) {
            return next.handle(request);
        } else {
            const currentToken = localStorage.getItem('token');
            if (currentToken != null) {
                return of(currentToken)
                    .pipe(
                        map(tokenString => JSON.parse(tokenString) as AuthToken),
                        switchMap((token: AuthToken) => {
                            const expiry = this.userService.parseToken(token.token).exp;
                            const tokenExpiryDate = new Date(expiry * 1000);
                            const currentDate = new Date();
                            return currentDate > tokenExpiryDate ? this.userService.refreshToken(token.token) : of(token);
                        }),
                        switchMap(tokenObj => {
                            const authReq = request.clone({setHeaders: {Authorization: `Bearer ${tokenObj.token}`}});
                            return next.handle(authReq);
                        })
                    );
            }

            return next.handle(request);
        }
    }
}
