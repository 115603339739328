<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h5>{{translationLabel.DictionaryFormsTitle | translate}}</h5>
            <form #form="ngForm" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="p-grid p-jc-start">
                    <div class="p-col-12 p-md-12 p-lg-3">
                        <div class="p-inputgroup">
                            <input type="text" pInputText name="word" id="id_search_text"
                                   formControlName="word"
                                   placeholder="{{translationLabel.DictionaryFormsSearchPlaceholder | translate}}"
                                   class="vk-input">
                            <button (click)="toggleKeyboard()" type="button" pButton
                                    class="bold p-button p-button-outlined p-input-icon-right">
                                <fa-icon [icon]="faKeyboard" size="lg"></fa-icon>
                            </button>
                        </div>
                    </div>

                    <div class="p-field-checkbox p-col-12 p-md-10 p-lg-2" style="margin-bottom: 0">
                        <p-checkbox id="searchEverywhere" formControlName="searchEverywhere" [binary]="true"
                                    inputId="searchEverywhere"></p-checkbox>
                        <label for="searchEverywhere">{{translationLabel.DictionaryFormsShowEverything | translate}}</label>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-auto">
                        <button type="submit" class="bold" style="width: 100%" pButton label="{{translationLabel.CommonSearchButton | translate}}">
                        </button>
                    </div>
                </div>

                <div class="p-grid" style="margin-top: -0.25rem;">
                    <div *ngIf="form.submitted" style="padding-left: 0.5rem; margin-bottom: 1rem">
                        <ng-container *ngIf="searchForm.get('word').errors?.minlength">
                            <small class="p-error block">{{translationLabel.ErrorWordIsTooShort | translate}}</small>
                        </ng-container>
                        <ng-container *ngIf="wordNotFoundError">
                            <!-- TODO translate-->
                            <small class="p-error block">{{wordNotFoundError}}</small>
                        </ng-container>
                        <ng-container *ngIf="searchForm.get('word').dirty && searchForm.get('word').invalid" style="width: 25%">
                            <small class="p-error block">{{translationLabel.ErrorMessageRequiredField | translate}}</small>
                        </ng-container>
                    </div>
                </div>
            </form>

            <div *ngIf="results" class="p-grid p-mt-3">
                <div *ngIf="results.dictionaryResults" class="p-col-12">
                    <p-table [value]="results.dictionaryResults"
                             [columns]="dictionaryCols"
                             styleClass="p-datatable-sm p-datatable-striped" responsiveLayout="scroll">
                        <ng-template pTemplate="caption">
                            <h5>{{translationLabel.AppMenuHistoricalDict | translate}}: {{translationLabel.DictionaryFormsFoundWordForms | translate}}: {{results.dictionaryResults.length}}, {{translationLabel.DictionaryFormsFor | translate}}
                                0.153 {{translationLabel.DictionaryFormsSeconds | translate}}</h5>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <ng-container *ngFor="let col of columns">
                                    <th scope="col">
                                        <em>{{col.header}}</em>
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td style="width: 15%">{{rowData.entryId}}</td>
                                <td style="width: 20%" class="cyrillic">
                                    <a target="_blank"
                                       [routerLink]="['/dictionary', rowData.entryId]">{{rowData.entry}}</a>
                                </td>
                                <td style="width: 20%" class="cyrillic">{{rowData.form}}</td>
                                <td style="width: 45%">{{rowData.conjugation}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <div *ngIf="results.allForms" class="p-col-12">
                    <p-table [value]="results.allForms"
                             [columns]="allFormsCols"
                             styleClass="p-datatable-sm p-datatable-striped" responsiveLayout="scroll">
                        <ng-template pTemplate="caption">
                            <h5>{{translationLabel.AppMenuHistoricalDict | translate}}: {{translationLabel.DictionaryFormsAllWordForms | translate}}</h5>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <ng-container *ngFor="let col of columns">
                                    <th scope="col">
                                        <em>{{col.header}}</em>
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td style="width: 15%"><abbr [title]="rowData.tagTitle">{{rowData.tag}}</abbr></td>
                                <td style="width: 85%" class="cyrillic">{{rowData.form}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-keyboard-sidebar></app-keyboard-sidebar>
