import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {

    constructor(private translateService: TranslateService, private titleService: Title) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState) || this.titleService.getTitle();
        if (title !== undefined) {
            this.translateService.stream(title).subscribe(translation => {
                document.title = `${translation} | Histdict`;
            });
        } else {
            document.title = 'Histdict';
        }
    }
}
