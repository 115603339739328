import {Component, OnInit} from '@angular/core';
import {UserService} from './services/user.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TranslationLabel} from './enum/translation-label';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DictionaryService} from './modules/dictionary/service/dictionary.service';
import {zip} from 'rxjs';
import {Dictionary} from './modules/dictionary/model/dictionary';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-menu',
    template: `
        <div class="menu">
            <ul class="layout-menu">
                <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i"
                    [root]="true"></li>
            </ul>
        </div>
    `
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[];
    translationKeys = [
        TranslationLabel.AppMenuHome,
        TranslationLabel.AppMenuLogin,
        TranslationLabel.AppMenuTextCorpus,
        TranslationLabel.AppMenuContents,
        TranslationLabel.AppMenuWordForms,
        TranslationLabel.AppMenuMedievalDict,
        TranslationLabel.AppMenuSearch,
        TranslationLabel.AppMenuNewText,
        TranslationLabel.AppMenuNewWord,
        TranslationLabel.AppMenuOtherResources,
        TranslationLabel.AppMenuAbout,
        TranslationLabel.AppMenuLogout,
        TranslationLabel.AppMenuCompositeDict
    ];

    constructor(private userService: UserService,
                private translateService: TranslateService,
                private router: Router,
                private dictionaryService: DictionaryService) {
    }

    ngOnInit() {
        this.loadMenus();

        this.translateService.onLangChange.pipe(
            switchMap((event: LangChangeEvent) => {
                return zip([this.dictionaryService.listDictionaries(),
                    this.translateService.stream(this.translationKeys)]);
            })
        ).subscribe(this.constructMenuList());
    }

    private loadMenus() {
        zip([this.dictionaryService.listDictionaries(),
            this.translateService.stream(this.translationKeys)])
            .subscribe(this.constructMenuList());
    }

    private constructMenuList() {
        return ([dictionaries, translations]) => {
            const isLoggedIn = this.userService.isLoggedIn();
            const dictionariesLinks: MenuItem[] = dictionaries.map(dict => ({
                label: new Dictionary(dict).getName(this.translateService.currentLang),
                routerLink: ['dictionary', dict.abbr.toLowerCase()],
                items: [
                    {
                        label: translations[TranslationLabel.AppMenuSearch],
                        routerLink: ['dictionary', dict.abbr.toLowerCase(), 'search']
                    },
                    {
                        label: translations[TranslationLabel.AppMenuNewWord],
                        routerLink: ['dictionary', dict.abbr.toLowerCase(), 'new'],
                        visible: isLoggedIn
                    },
                ]
            }));

            this.model = [
                {label: translations[TranslationLabel.AppMenuHome], routerLink: ['/']},
                {
                    label: translations[TranslationLabel.AppMenuLogin],
                    routerLink: ['/login', {queryParams: {returnUrl: this.router.routerState.snapshot.url}}],
                    visible: !isLoggedIn
                },
                {
                    label: translations[TranslationLabel.AppMenuTextCorpus], routerLink: ['/corpus'],
                    items: [
                        {label: translations[TranslationLabel.AppMenuContents], routerLink: ['/corpus']},
                        {
                            label: translations[TranslationLabel.AppMenuNewText],
                            routerLink: ['/corpus/new'],
                            visible: isLoggedIn
                        }
                    ]
                },
                ...dictionariesLinks,
                {label: translations[TranslationLabel.AppMenuSearch], routerLink: ['/search']},
                {label: translations[TranslationLabel.AppMenuWordForms], routerLink: ['/resolve-forms']},
                {
                    label: translations[TranslationLabel.AppMenuMedievalDict],
                    url: 'https://e-medievalia.uni-sofia.bg/moodle/mod/data/view.php?id=1869'
                },
                {label: translations[TranslationLabel.AppMenuOtherResources], routerLink: ['/other-resources']},
                {label: translations[TranslationLabel.AppMenuAbout], routerLink: ['/about']},
                {label: translations[TranslationLabel.AppMenuLogout], routerLink: ['/logout'], visible: isLoggedIn},
            ];
        };
    }
}
