import {Directive, ElementRef} from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[toggle]'
})
export class ToggleDirective {

    private shown = false;

    constructor(private el: ElementRef) {
        const parent = this.el.nativeElement.parentNode;
        const eyeIcon = document.createElement('i');
        eyeIcon.className = 'pi pi-eye';
        eyeIcon.style.cssText = 'cursor: pointer';
        eyeIcon.addEventListener('click', () => {
            this.toggle(eyeIcon);
        });
        parent.appendChild(eyeIcon);
    }

    toggle(span: HTMLElement) {
        this.shown = !this.shown;

        if (this.shown) {
            this.el.nativeElement.setAttribute('type', 'text');
            span.className = 'pi pi-eye-slash';

        } else {
            this.el.nativeElement.setAttribute('type', 'password');
            span.className = 'pi pi-eye';

        }
    }
}
