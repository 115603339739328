import {Component, OnDestroy, OnInit} from '@angular/core';
import {faKeyboard} from '@fortawesome/free-regular-svg-icons';
import * as VK from 'virtual-keyboard/virtualkeyboard';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BreadcrumbService} from '../../../../app.breadcrumb.service';
import {SearchService} from '../../../search/search.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationLabel} from 'src/app/enum/translation-label';
import {KeyboardService} from '../../../shared/keyboard.service';

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit, OnDestroy {

    faKeyboard = faKeyboard;

    searchForm: FormGroup = new FormGroup({
        word: new FormControl('', [Validators.required, Validators.minLength(4)]),
        searchEverywhere: new FormControl<boolean | null>(null)
    }, {updateOn: 'submit'});
    wordNotFoundError: string;
    results: any;
    dictionaryCols: any;
    allFormsCols: any;

    translationLabel: typeof TranslationLabel = TranslationLabel;

    constructor(private breadcrumbService: BreadcrumbService,
                private searchService: SearchService,
                private translateService: TranslateService,
                private keyboardService: KeyboardService) {
        translateService.stream([
            TranslationLabel.AppMenuHistoricalDict,
            TranslationLabel.DictionaryFormsArticle,
            TranslationLabel.DictionaryFormsTitleWord,
            TranslationLabel.DictionaryFormsWordForm,
            TranslationLabel.DictionaryFormsConjugation,
            TranslationLabel.DictionaryFormsTag,
            TranslationLabel.DictionaryFormsWordForms
        ]).subscribe(translations => {
            this.breadcrumbService.setItems([
                {label: translations[TranslationLabel.AppMenuHistoricalDict]}
            ]);
            this.dictionaryCols = [
                {field: 'entryId', width: '15%', header: translations[TranslationLabel.DictionaryFormsArticle]},
                {
                    field: 'entry',
                    width: '20%',
                    header: translations[TranslationLabel.DictionaryFormsTitleWord],
                    styleClass: 'cyrillic'
                },
                {
                    field: 'form',
                    width: '20%',
                    header: translations[TranslationLabel.DictionaryFormsWordForm],
                    styleClass: 'cyrillic'
                },
                {field: 'conjugation', width: '45%', header: translations[TranslationLabel.DictionaryFormsConjugation]}
            ];
            this.allFormsCols = [
                {field: 'tag', width: '15%', header: translations[TranslationLabel.DictionaryFormsTag]},
                {
                    field: 'form',
                    width: '85%',
                    header: translations[TranslationLabel.DictionaryFormsWordForms],
                    styleClass: 'cyrillic'
                },
            ];
        });
    }

    ngOnInit(): void {
    }

    toggleKeyboard() {
        this.keyboardService.toggleKeyboardVisibility('id_search_text');
    }

    search() {
        if (this.searchForm.valid) {
            if (!this.searchForm.controls.word) {
                this.searchForm.controls.word = VK.VirtualKeyboard.getAttachedInput()?.value;
            }
            this.searchService.searchForms(this.searchForm.controls.word.value, this.searchForm.controls.searchEverywhere.value)
                .subscribe({
                    next: results => this.results = results,
                    error: () => this.translateService.get(TranslationLabel.ErrorWordNotFound) // TODO handle different errors, 400 vs 500
                        .subscribe(translation => this.wordNotFoundError = translation)
                });
        } else {
            this.searchForm.controls.word.markAsDirty();
            this.searchForm.controls.searchEverywhere.markAsDirty();
        }

        this.keyboardService.hideKeyboard();
    }

    ngOnDestroy(): void {
        this.keyboardService.hideKeyboard();
    }
}
