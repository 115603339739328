<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="title-container">
                <h1>Histdict</h1>

                <!--                TODO put another description-->
                <p>{{translationLabel.AppAboutPageObjectiveParagraph1 | translate}}</p>
            </div>
            <h5>{{translationLabel.AppMenuTextCorpus | translate}}</h5>

            <p-table [value]="genres" [showLoader]="true" [loading]="loading"
                     styleClass="p-datatable-sm corpus-list" responsiveLayout="scroll"
                     breakpoint="680px">
                <ng-template pTemplate="body" let-genre>
                    <tr class="corpus-row">
                        <td style="width: 1em; padding-left: 0">
                            <i class="pi pi-chevron-right"></i>
                        </td>
                        <td>
                            <a style="font-size: 16px" [routerLink]="['/corpus']"
                               [queryParams]="{genre: genre.text}">{{genre.text}}</a>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2"
                            class="empty-table-message">{{translationLabel.ErrorSearchNoResultsFound | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="card">
            <div class="home-logo">
                <img alt="European Regional Development Fund logo" class="partner-logo" height="100"
                     ngSrc="assets/images/EU-logo.png"
                     priority width="291">
                <img alt="Nasledstvo.BG logo" class="partner-logo" height="100" ngSrc="assets/images/nasledstvo-bg.jpg"
                     priority width="326">
                <img alt="Science and Education for Smart Growth logo" class="partner-logo" height="100"
                     ngSrc="assets/images/logo-bg-right-no-back.png"
                     priority width="276">
            </div>
            <div id="about-text">
                {{translationLabel.AppMainProjectInfo | translate}}
            </div>
        </div>
    </div>
</div>
