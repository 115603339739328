import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslationLabel} from '../enum/translation-label';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '../services/loading.service';

@Component({
    selector: 'app-login',
    styleUrls: ['./app.login.component.scss'],
    templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {

    loginForm: FormGroup = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
    });
    hasErrorMessage = false;
    translationLabel: typeof TranslationLabel = TranslationLabel;
    returnUrl: string;
    currentYear = new Date().getFullYear();

    isLoading: boolean;

    constructor(
        private userService: UserService,
        private loadingService: LoadingService,
        private router: Router,
        private route: ActivatedRoute) {
        this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    }

    ngOnInit(): void {
        this.loadingService.loading$.subscribe(loading => this.isLoading = loading);
    }

    login() {
        this.hasErrorMessage = false;
        if (this.loginForm.valid) {
            const username = this.loginForm.value.username;
            const password = this.loginForm.value.password;
            this.userService.login(username, password)
                .subscribe({
                    next: () => this.router.navigateByUrl(this.returnUrl),
                    error: err => this.handleError(err)
                });
        } else {
            Object.keys(this.loginForm.controls).forEach(key => {
                this.loginForm.controls[key].markAsDirty();
            });
        }
    }

    handleError(error): void {
        if (error.status === 401) {
            this.hasErrorMessage = true;
        }
    }
}
