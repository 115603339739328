import {Component} from '@angular/core';
import {TranslationLabel} from '../enum/translation-label';
import {LoadingService} from '../services/loading.service';

@Component({
    selector: 'app-notfound',
    templateUrl: './app.notfound.component.html',
    styleUrls: ['app.notfound.component.scss']
})
export class AppNotfoundComponent {

    currentYear = new Date().getFullYear();

    translationLabel: typeof TranslationLabel = TranslationLabel;

    constructor(public loadingService: LoadingService,
    ) {
    }
}
